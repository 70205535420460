import React, { useEffect, useState, useRef, useMemo } from 'react';
import { toJS } from 'mobx';
import { List, Skeleton, Result, Divider, Row, Col } from 'antd';
import { FilePdfOutlined, FileWordOutlined, FileExcelOutlined, FileImageOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAuthStore, useUserStore} from '../hooks/use-stores';
import { useNavigate } from 'react-router-dom';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import Config from '../Config';

const UserDocuments = (props) => {
	const navigate = useNavigate();
	const [editedItem, setEditedItem] = useState(null);
	const [editedItemError, setEditedItemError] = useState(null);
	const authStore = useAuthStore();
	const userStore = useUserStore();

	useEffect( () => {
		fetchResourceAndUpdate();
	}, []);

	const documentsFileList = useMemo( () => {
		if (!editedItem) {
			return [];
		}
		return (editedItem.files || []).sort( (a, b) => {
			return b.date - a.date;
		}).map( (doc, ndx) => ({
			...doc
			,status: 'done'
			,ndx: ndx
			,url: `${Config.API_ROOT}/uploads/${doc.path}`
		}) );
	}, [editedItem]);

	const fetchResourceAndUpdate = () => {
		userStore.get(authStore.id).then( result => {
			console.log('got resource', result);
			setEditedItem( toJS(result) );
		}).catch( err => {
			setEditedItemError(err.message);
		});
	}

	const getFileIcon = (item) => {
		const ICONS_BY_MIMETYPE = {
			'application/pdf': <FilePdfOutlined />
			,'application/msword': <FileWordOutlined />
			,'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <FileWordOutlined />
			,'application/vnd.oasis.opendocument.text': <FileWordOutlined />
			,'application/vnd.ms-excel': <FileExcelOutlined />
			,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <FileExcelOutlined />
			,'image/png': <FileImageOutlined />
			,'image/jpeg': <FileImageOutlined />
		}
		if (ICONS_BY_MIMETYPE[item.mimetype]) {
			return <span className="ml-10">
			{ICONS_BY_MIMETYPE[item.mimetype]}
			</span>
		}
		return null;
	};

	if (!editedItem) {
		if (editedItemError) {
			return <Result status="error" title="Errore"
				subTitle={<div>
					Impossibile recuperare la risorsa richiesta<br/>Errore: {editedItemError}
					</div>}
				/>
		}
		return <Skeleton />
	}

	return <div>
		<Divider orientation="left">Lista ricevute e documenti</Divider>
		<Row>
			<Col xxl={12} lg={14} xs={24}>
				<List dataSource={documentsFileList} className="mt-30"
				 renderItem={item => (
					<List.Item actions={[
						<a download href={item.url}><DownloadOutlined /></a>
					]}>
						<a href={item.url} target="_blank">{item.name}{getFileIcon(item)}</a>
					</List.Item>
				 )}
				/>
			</Col>
		</Row>
	</div>

}

export default UserDocuments;
