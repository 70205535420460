import { makeObservable, observable, computed, action } from 'mobx'

class BaseStore {
	static observableAnnotations = {
		rootStore: false
		,baseModel: false
		,_skipPatchLocalItem: false

		,items: observable
		,_isLoading: observable
		,_loaded: observable
		,_dirty: observable

		,setItems: action
		,load: action
		,create: action
		,patch: action
		,remove: action
		,patchLocalItem: action
		,deleteLocalItem: action
	};

	items = [];
	_isLoading = false;
	_loaded = false;
	_dirty = false;

	_skipPatchLocalItem = false;

	constructor(rootStore, opts) {
		opts = opts || {};

		this.rootStore = rootStore;
		this.baseModel = opts.model || null;

		makeObservable(this, BaseStore.observableAnnotations);
	}

	setItems(items){
		this.items = [];
		if (this.baseModel){
			this.items.push.apply(this.items, items.map( item => new this.baseModel(item) ));
		} else {
			this.items.push.apply(this.items, items);
		}
	}

	load(opt) {
		console.log('fetching...');
		this._isLoading = true;
		return this.loadImplementation(opt).then( (items) => {
			this._isLoading = false;
			this._loaded = true;
			this._dirty = false;
			return items;
		}).catch( (err) => {
			this._isLoading = false;
			throw err;
		});
	}

	stdServiceFindAndSet(opt) {
		if (opt.fetchAll && !opt.results) {
			opt.results = [];
		}
		if (!opt.query) {
			opt.query = {};
		}
		const findParams = { query: opt.query };
		return this.rootStore.services[opt.service].find(findParams).then( result => {
			if (opt.fetchAll){
				opt.results = opt.results.concat(result.data);
				if (result.total > result.skip + result.data.length) {
					opt.query['$skip'] = result.skip + result.data.length;
					return this.stdServiceFindAndSet(opt);
				}
				if (!opt.dontSet) {
					this.setItems(opt.results);
				}
				return opt.results;
			}

			if (!opt.dontSet) {
				this.setItems(result.data);
			}
			if (opt.fullResponse) {
				return result;
			}
			return result.data;
		});
	}

	get(id, opts) {
		return this.getImplementation(id, opts);
	}

	stdServiceGet(id, opt) {
		opt = opt || {};
		let params = opt.query ? { query: opt.query } : null;
		return this.rootStore.services[opt.service].get(id, params);
	}

	create(values) {
		return this.createImplementation(values).then( (item) => {
			this._dirty = true;
			return item;
		});
	}

	patch(id, values, params){
		return this.patchImplementation(id, values, params).then( (item) => {
			if (item && !this._skipPatchLocalItem){
				/*if (!this.baseModel){
					throw new Error('You must provide a base model to the store if you want to update items');
				}*/
				this.patchLocalItem(item);
			}
			return item;
		});
	}

	stdServiceCreate(data, params, opt) {
		opt = opt || {};
		return this.rootStore.services[opt.service].create(data, params);
	}

	stdServicePatch(id, data, params, opt) {
		opt = opt || {};
		return this.rootStore.services[opt.service].patch(id, data, params);
	}

	patchLocalItem(values){
		if (!this.baseModel || !this.baseModel.properties){
			/*throw new Error('the base model should have a static "properties" defined');*/
			//console.log('no correct basemodel provided, extending the object');
			let filtered = this.items.filter( (item) => item._id == values._id );
			filtered.forEach( item => {
				console.log('Patching local item...');
				Object.assign(item, values);
			});
			return;
		}
		let filtered = this.items.filter( (item) => item._id == values._id );	
		filtered.forEach( item => {
			console.log('Patching local item...');
			this.baseModel.properties.forEach( key => {
				item[key] = values[key];
			});
		});
	}

	remove(id) {
		return this.removeImplementation(id).then( () => {
			this.deleteLocalItem(id);
			return id;
		});
	}

	stdServiceRemove(id, opt) {
		opt = opt || {};
		return this.rootStore.services[opt.service].remove(id);
	}

	deleteLocalItem(id) {
		let index = this.items.findIndex( (item) => item._id == id );
		if (index != -1){
			console.log('found, deleting local item');
			this.items.splice(index, 1);
		}
	}
}

export default BaseStore;


