import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Spin, Typography, Form, Input, Button, notification, Row, Col } from 'antd';
import { LoadingOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';

import { withSimpleLayout } from '../layouts/Layouts';
import { getAbsoluteURL } from '../common/common';

const Login = withSimpleLayout( observer( (props) => {
        const authStore = useAuthStore();
	const location = useLocation();
        const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;
	const navigate = useNavigate();

	useEffect( () => {
		if (authStore.isAuthenticated) {
			console.log('redirect to value', redirectTo);
			navigate(redirectTo);
		}
	}, [authStore.isAuthenticated]);

        if (authStore.isAuthenticated === null){
                return (<div>
                        Controllo autenticazione... <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spinning />} />
                </div>);
        }
	else if (authStore.isAuthenticated) {
		//if we're here, we're logged in, the useEffect will perform redirection
		return <div>reindirizzamento...</div>
	}

	return (<div className="login-form-container page-form-container">
		<Typography.Title level={2} className="text-center">ZeroInCondottA Login Utente</Typography.Title>
		<LoginForm store={authStore} />
	</div>);
}) );

const LoginForm = observer( (props) => {
        function handleFailedSubmit(errorInfo) {
                console.log(errorInfo);
        }

        function handleSubmit(values) {
                props.store.login(values.email, values.password).catch( (err) => {
                        notification.error({
                                message: 'Impossibile autenticarsi'
                                ,description: 'Per favore controlla la tua email o la tua password'
                                ,duration: 5
                        });
                });
        }

        return (
                <Form name='login-form' className="login-form" layout='vertical' onFinish={handleSubmit} onFinishFailed={handleFailedSubmit}>
                        <Form.Item label='Email' name='email' rules={ [ { required: true, message: 'Inserisci la tua email' }, { type: 'email', message: 'La mail non è valida' } ] }>
                                <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                        type="email" placeholder="email/username" />
                        </Form.Item>
                        <Form.Item label='Password' name='password' rules={ [ { required: true, message: 'Inserisci la password' } ] }>
                                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password" placeholder="password" />
                        </Form.Item>
                        <Form.Item>
				<Row justify="space-between">
					<Col><Link to={getAbsoluteURL('/auth/password-forgotten')}>Password dimenticata?</Link></Col>
					{/*<Col><Link to={getAbsoluteURL('/auth/signup')}>Registrati</Link></Col>*/}
				</Row>
                        </Form.Item>
                        <Form.Item>
                                <Button className="submit ant-btn-block" type="primary" htmlType="submit" disabled={props.store._isLoggingIn}>Login</Button>
                        </Form.Item>
                </Form>
        );
});

export default Login;
