import React from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Config from './Config';
import Login from './pages/Login';
import Register from './pages/Register';
import Verification from './pages/Verification';
import PasswordForgotten from './pages/PasswordForgotten';
import PasswordReset from './pages/PasswordReset';
import { MainLayout } from './layouts/Layouts';
import { getAbsoluteURL, cleanPath } from './common/common';
import { observer } from 'mobx-react-lite';
import { ProvideRootStore, useRootStore } from './hooks/use-stores';
import { ConfigProvider } from 'antd';
import itIT from 'antd/lib/locale/it_IT';
import moment from 'moment';
import 'moment/locale/it';

import './App.less';

//console.log(Config);

function App() {
	const loc = useLocation();
	const navigate = useNavigate();
	React.useEffect( () => {
		const cleanedPath = cleanPath(loc.pathname);
		if (cleanedPath.indexOf(Config.MOUNT_POINT) !== 0) {
			const redirectTo = getAbsoluteURL(cleanedPath);
			console.log('redirecting to mount point', redirectTo);
			navigate(redirectTo);
		}
	}, []);

	return (
	<div className="App">
		<Routes>
			<Route path={getAbsoluteURL('/auth/login')} element={<Login />} />
			{/*<Route path={getAbsoluteURL('/auth/signup')} element={<Register />} />*/}
			<Route path={getAbsoluteURL('/auth/verify/:tokenId')} element={<Verification />} />
			<Route path={getAbsoluteURL('/auth/password-forgotten')} element={<PasswordForgotten />} />
			<Route path={getAbsoluteURL('/auth/password-reset/:tokenId')} element={<PasswordReset />} />
			<Route path={getAbsoluteURL('/*')} element={<MainLayout />} />
		</Routes>
	</div>
	);
}

function AppWrapper() {
	moment.locale('it');

	return (<ProvideRootStore>
		<ConfigProvider locale={itIT}>
			<App />
		</ConfigProvider>
	</ProvideRootStore>
	);
}

export default AppWrapper;
