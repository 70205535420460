import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { Spin, Typography, Alert, Row, Col } from 'antd';
import { LoadingOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';

import { withSimpleLayout } from '../layouts/Layouts';
import { getAbsoluteURL } from '../common/common';

const Verification = withSimpleLayout( observer( (props) => {
        const authStore = useAuthStore();
	const location = useLocation();
        const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;
	const navigate = useNavigate();
	const params = useParams();
	const [status, setStatus] = useState(null); //'ok', 'ko', null
	const [errorMessage, setErrorMessage] = useState('');

	useEffect( () => {
		authStore.verifySignup(params.tokenId).then( () => {
			setStatus('ok');
			setTimeout( () => {
				navigate(redirectTo);
			}, 4500);
		}).catch( err => {
			console.error(err);
			setErrorMessage(err.message);
			setStatus('ko');
		});
	}, []);

	return (<div className="page-form-container">
		<Typography.Title level={2} className="text-center">ZeroInCondottA Verifica Mail</Typography.Title>
		<div className="padded-container">
			{ status === null && <div>
				Verifica email in corso... <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spinning={true} />} />
			</div>}
			{ status === 'ok' &&
				<Alert message="Mail verificata con successo" type="success" showIcon />
			}
			{ status === 'ko' &&
				<Alert message={`Verifica fallita, riprova a verificare la tua mail. Errore: ${errorMessage}`} type="error" showIcon />
			}
			<Row justify="space-between" className='mt-30'>
				<Col><Link to={getAbsoluteURL('/auth/login')}>Login</Link></Col>
				<Col><Link to={getAbsoluteURL('/auth/password-forgotten')}>Password dimenticata?</Link></Col>
			</Row>
		</div>
	</div>
	);
}) );

export default Verification;
