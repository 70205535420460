import React, { useEffect } from 'react';

function ScrollToTopOnMount(props) {
	useEffect(() => {
		setTimeout( () => {
			window.scrollTo(0, 0);
		}, props.delay || 100);
	}, []);

	return null;
}

export default ScrollToTopOnMount;
