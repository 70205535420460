import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Button, Avatar, Row, Col, Menu, Dropdown, Space } from 'antd';
import { LogoutOutlined , MenuUnfoldOutlined, MenuFoldOutlined, ScanOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores'
import { getAbsoluteURL } from '../common/common';


function MainHeader(props) {
	const authStore = useAuthStore();
	const userDisplayName = authStore.firstName && authStore.lastName ? `${authStore.firstName} ${authStore.lastName}` : authStore.email; //.replace(/@.*/,'');

	function logout() {
		authStore.logout();
	}

	const accountMenuItems = [
		{ key: 'username', label: authStore.email || 'anonymous', icon: <UserOutlined /> }
		,{ key: 'logout', label: 'Logout', icon: <LogoutOutlined /> }
	];
	const onMenuClick = ({ item, key, keyPath }) => {
		if (key == 'logout') {
			logout();
		}
	};

	const accountMenu = (<Menu items={accountMenuItems} onClick={onMenuClick} />);
	const TriggerIconComponent = props.sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

	return (
		<Row wrap={false}>
			<Col flex="1 1 auto">
				<Button type="link" className="trigger" onClick={props.toggleSidebar} icon={<TriggerIconComponent  />} />
			</Col>
			<Col flex="0 1 auto">
				<Space>
					<div className="user-info d-inline-block">
						<Dropdown overlay={accountMenu} trigger={['hover', 'click']}>
							<span className='d-inline-block'>
								<Avatar style={{ verticalAlign: 'middle' }} size="default">
									{authStore.email && authStore.email.charAt(0).toUpperCase()}
								</Avatar>
								{authStore.email &&
								<span className="user-name ellipsis d-none d-sm-inline-block">{userDisplayName}</span>
								}
							</span>
						</Dropdown>
					</div>
				</Space>
			</Col>
		</Row>
	);

}

export default MainHeader;
