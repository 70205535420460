import { makeObservable, observable, computed } from 'mobx'
import BaseStore from './base-store';

const SERVICE_NAME = 'courses';

class CourseStore extends BaseStore {
	constructor(rootStore, opts) {
		super(rootStore, opts);
	}

	loadImplementation(opt) {
		return this.stdServiceFindAndSet({
			service: SERVICE_NAME
			,...opt
		});
	}

	getImplementation(id, opt) {
		return this.stdServiceGet(id, {
			service: SERVICE_NAME
			,...opt
		});
	}

	createImplementation(data, params) {
		return this.stdServiceCreate(data, params, { service: SERVICE_NAME });
	}

	patchImplementation(id, data, params) {
		return this.stdServicePatch(id, data, params, { service: SERVICE_NAME });
	}

	removeImplementation(id) {
		return this.stdServiceRemove(id, { service: SERVICE_NAME });
	}
}

export default CourseStore;
