import _ from 'lodash';

const QRCODE_USELESS_PREFIX = _.repeat('A', 25) + ' ';

const getFullQRCode = (cardCode) => {
	return cardCode ? `${QRCODE_USELESS_PREFIX}${cardCode}` : cardCode;
}

export {
	getFullQRCode
}
