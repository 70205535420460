import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import Config from '../Config';
import { getAbsoluteURL } from './common';

const requiresAuthentication = (WrappedComponent) => {
	return observer( (props) => {
		const authStore = useAuthStore();
		const location = useLocation();
		const navigate = useNavigate();
		const loginUrl = getAbsoluteURL('/auth/login');

		useEffect( () => {
			if (authStore.isAuthenticated === false) {
				console.log(`not authenticated, from: ${location.pathname} to ${loginUrl}`);
				navigate(loginUrl, { state: { from: location.pathname }, replace: true });
			}
		}, [authStore.isAuthenticated]);

		if (authStore.isAuthenticated) {
			return <WrappedComponent {...props} />
		}
		else if (authStore.isAuthenticated === null) {
			<div>caricamento, attendere prego...</div>
		}

		return (
			<div>reindirizzamento...</div>
		);
	}
	);
}

export default requiresAuthentication;
