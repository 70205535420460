import feathers, { rest, authentication } from '@feathersjs/client';

import Config from '../Config';

const app = feathers();

// Connect to a different URL
const restClient = rest(Config.API_ROOT);

// Configure an AJAX library (see below) with that client 
app.configure(restClient.fetch(window.fetch));

app.configure(authentication({
        storageKey: 'jwtauth'
}))


// Connect to the `httpX://xxxxxxxxx/users` ... services
const services = {};
services.users = app.service('users');
services.courses = app.service('courses');
services.subscriptions = app.service('subscriptions');
services.authManagement = app.service('authmgmt');
services.miscmgmt = app.service('miscmgmt');

export default {
        app
        ,services
}
