import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Spin, Typography, Form, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';

import { withSimpleLayout } from '../layouts/Layouts';
import { getAbsoluteURL } from '../common/common';

const PasswordForgotten = withSimpleLayout( (props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useAuthStore();
	const navigate = useNavigate();

	useEffect( () => {
		if (isSubmitted) {
			navigate(getAbsoluteURL('/auth/login'));
		}
	}, [isSubmitted]);

	function onSubmit(values) {
		setSubmitting(true);
		authStore.sendPasswordResetEmail(values.email).then( () => {
			notification.info({
				message: 'Richiesto link di reset'
				,description: 'Se il tuo indirizzo è nel nostro database, a breve riceverai una mail contenente il link per reimpostare la password'
				,duration: 8
			});
			setIsSubmitted(true);
		}).catch( (err) => {
			notification.error({
				message: 'Errore'
				,description: 'Impossibile inviare il link di reset password. Prova ancora, grazie'
				,duration: 8
			});
		}).then( () => {
			setSubmitting(false);
		});
	}

	if (isSubmitted){
		return <div>reindirizzamento...</div>;
	}

	return (<div className="password-forgotten-form-container page-form-container">
		<Typography.Title level={2} className="text-center">ZeroInCondotta Reset Password</Typography.Title>
		<PasswordForgottenForm store={authStore} onSubmit={onSubmit} submitting={submitting} />
	</div>);
} );

const PasswordForgottenForm = (props) => {
	function handleFailedSubmit(errorInfo) {
		console.log(errorInfo);
	}
	function handleSubmit(values) {
		props.onSubmit(values);
	}

	return (
		<Form name='password-forgotten-form' className="password-forgotten-form" layout='vertical' onFinish={handleSubmit} onFinishFailed={handleFailedSubmit}>
			<Form.Item label='Email' name='email'
				rules={ [ {type: 'email', message: 'La mail inserita non è valida!' }
					,{ required: true, message: 'Inserisci la tua email' }
				] }
			>
				<Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
					type="email" placeholder="email" />
			</Form.Item>
			<Form.Item>
				<Link to={getAbsoluteURL('/auth/login')}>Vai al Login</Link>
			</Form.Item>
			<Form.Item>
				<Button className="submit ant-btn-block" type="primary" htmlType="submit" disabled={props.submitting}>Richiedi link di reset</Button>
			</Form.Item>
		</Form>
	);
};

export default PasswordForgotten;
