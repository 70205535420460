import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Spin, Typography, Form, Input, Button, notification, Row, Col } from 'antd';
import { LoadingOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';

import { withSimpleLayout } from '../layouts/Layouts';
import { getAbsoluteURL, validatePassword } from '../common/common';

const Register = withSimpleLayout( observer( (props) => {
        const authStore = useAuthStore();
	const location = useLocation();
        const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;
	const navigate = useNavigate();

	useEffect( () => {
		if (authStore.isAuthenticated) {
			console.log('redirect to value', redirectTo);
			navigate(redirectTo);
		}
	}, []);

        if (authStore.isAuthenticated){
		//if we're here, we're logged in
		return <div>redirecting...</div>
        }

	return (<div className="signup-form-container page-form-container">
		<Typography.Title level={2} className="text-center">ZeroInCondottA Registrazione</Typography.Title>
		<RegisterForm store={authStore} navigate={navigate} />
	</div>);
}) );

const RegisterForm = observer( (props) => {
	const [submitting, setSubmitting] = useState(false);

        function handleFailedSubmit(errorInfo) {
                console.log(errorInfo);
        }

        function handleSubmit(values) {
		setSubmitting(true);
                props.store.signup({
			email: values.email, password: values.password
			,firstName: values.firstName, lastName: values.lastName
		}).then( () => {
			notification.success({
				message: 'Registrazione avvenuta con successo'
				,description: 'La registrazione è andata a buon fine. Controlla la casella di posta per confermare la tua email'
				,duration: 5
			});
			return true;
		}).catch( (err) => {
			if (err.name === 'Conflict') {
				notification.error({
					message: 'Impossibile registrarsi'
					,description: 'La mail potrebbe già essere in uso, prova con un\'altra'
					,duration: 5
				});
			}
			else {
				notification.error({
					message: 'Impossibile registrarsi'
					,description: `Errore durante la registrazione: ${err.message}`
					,duration: 5
				});
			}
			return false;
                }).then( (isOk) => {
			setTimeout( () => {
				setSubmitting(false);
			}, 3500);
			if (isOk) {
				props.navigate(getAbsoluteURL('/auth/login'));
			}
		});
        }

	function getPasswordConfirmValidator({ getFieldValue }) {
		return {
			validator: (_, value) => {
				if (!value || getFieldValue('password') === value) {
					return Promise.resolve();
				}
				return Promise.reject(new Error('Le password inserite non coincidono'));
			}
		}
		
	}

        return (
                <Form name='register-form' className="register-form" layout='vertical' onFinish={handleSubmit} onFinishFailed={handleFailedSubmit}>
                        <Form.Item label='Email' name='email' rules={ [ { required: true, message: 'Inserisci la tua email' }, { type: 'email', message: 'La mail non è valida' } ] }>
                                <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                        type="email" placeholder="email" />
                        </Form.Item>
                        <Form.Item label='Password' name='password' rules={ [ { required: true, message: 'Inserisci la password' }, { validator: validatePassword } ] } hasFeedback>
                                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password" placeholder="password" />
                        </Form.Item>
                        <Form.Item label='Conferma password' name='password-confirm'
				rules={ [ { required: true, message: 'Inserisci la conferma della password' }, getPasswordConfirmValidator ] }
			 	hasFeedback dependencies={['password']}
			>
                                <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password" placeholder="password" />
                        </Form.Item>
                        <Form.Item label='Nome' name='firstName'
				rules={ [ { required: true, message: 'Inserisci il tuo nome' }, { min: 3, message: 'Nome e cognome devono essere lunghi almeno 3 caratteri' } ] }
			>
                                <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                        type="text" placeholder="Nome" />
                        </Form.Item>
                        <Form.Item label='Cognome' name='lastName'
				rules={ [ { required: true, message: 'Inserisci il tuo cognome' }, { min: 3, message: 'Nome e cognome devono essere lunghi almeno 3 caratteri' } ] }
			>
                                <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                                        type="text" placeholder="Cognome" />
                        </Form.Item>
                        <Form.Item>
				<Row justify="end">
                                	<Col><Link to={getAbsoluteURL('/auth/login')}>Sei già registrato?</Link></Col>
				</Row>
                        </Form.Item>
                        <Form.Item>
                                <Button className="submit ant-btn-block" type="primary" htmlType="submit" disabled={submitting}>Registrati</Button>
                        </Form.Item>
                </Form>
        );
});

export default Register;
