import React, { useEffect, useState, useRef, useMemo } from 'react';
import { toJS } from 'mobx';
import { useAuthStore, useUserStore, useMiscMgmtStore } from '../hooks/use-stores';
import { useNavigate } from 'react-router-dom';
import { Form, Input, InputNumber, Select, Checkbox, Button, Skeleton, Grid, Divider, notification, Typography, Row, Col, Result, DatePicker, Radio, Space, Alert, Modal } from 'antd';
import _ from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import DEFS from '../common/defs'
import { getAbsoluteURL, validatePassword, validateFiscalCode } from '../common/common';
import moment from 'moment';
import { getFullQRCode } from '../common/all-ui-common';
import ModalPromptConfirm from '../common/modal-prompt-confirm';
import { QRCodeSVG } from 'qrcode.react';
import { saveAs } from 'file-saver';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const LABEL_COL = { lg: { span: 24 }, xl: { span: 24 } };
const WRAPPER_COL = { lg: {span: 18 }, xl: { span: 14 } };

const SERVICE_TO_LOCAL_PROPERTIES_MAPPER = [
	/*{ key: 'password' }*/
	{ key: 'email' }
	,{ key: 'permissions' }
	,{ key: 'firstName' }
	,{ key: 'lastName' }
	,{ key: 'sex' }
	,{ key: 'phone' }
	,{ key: 'landlinePhone' }
	,{ key: 'birthdate', mapper: (src, dst) => {
		if (src.birthdate) {
			dst.birthdate = moment(src.birthdate);
		}
	} }
	,{ key: 'birthplace' }
	,{ key: 'fiscalCode' }
	,{ key: 'address' }
	,{ key: 'medicalCertExpDate', mapper: (src, dst) => {
		if (src.medicalCertExpDate) {
			dst.medicalCertExpDate = moment(src.medicalCertExpDate);
		}
	} }
	,{ key: 'nsiMembership', mapper: (src, dst) => {
		if (src.nsiMembership) {
			let o = src.nsiMembership;
			if (o instanceof Object && o.expDate) {
				o.expDate = moment(o.expDate);
			}
			dst.nsiMembership = o;
		}
	} }
	,{ key: 'parent', mapper: (src, dst) => {
		if (src.parent) {
			let o = src.parent;
			if (o instanceof Object && o.birthdate) {
				o.birthdate = moment(o.birthdate);
			}
			dst.parent = o;
		}
	} }
	,{ key: 'notes' }
	,{ key: 'alwaysGrantedTurnstiles' }
];

const LOCAL_TO_SERVICE_PROPERTIES_MAPPER = [
	{ key: 'email' }
	,{ key: 'password' }
	,{ key: 'permissions' }
	,{ key: 'firstName' }
	,{ key: 'lastName' }
	,{ key: 'sex' }
	,{ key: 'phone' }
	,{ key: 'landlinePhone' }
	,{ key: 'birthdate' }
	,{ key: 'birthplace' }
	,{ key: 'fiscalCode' }
	,{ key: 'address' }
	,{ key: 'medicalCertExpDate' }
	,{ key: 'nsiMembership' }
	,{ key: 'parent' }
	,{ key: 'notes' }
	,{ key: 'alwaysGrantedTurnstiles' }
];

const UserEdit = (props) => {
	const navigate = useNavigate();
	const isNew = props.isNew || false;
	const [editedItem, setEditedItem] = useState(null);
	const [editedItemError, setEditedItemError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const miscMgmtStore = useMiscMgmtStore();
	const [form] = Form.useForm();
	const birthdateValue = Form.useWatch('birthdate', form);
	const [cardSummary, setCardSummary] = useState();
	const emailValue = editedItem && editedItem.email || '';
	const isUnderageUser = useMemo( () => {
		if (!birthdateValue) {
			return false;
		}
		return moment().diff(birthdateValue, 'years') < 18;
	}, [birthdateValue]);
	const fullQRCode = useMemo( () => {
		if (!editedItem) {
			return null;
		}
		return getFullQRCode(editedItem.cardCode);
	}, [editedItem]);

	useEffect( () => {
		if (isNew) {
			return;
		}
		fetchResourceAndUpdate();
	}, []);

	useEffect( () => {
		if (!editedItem) {
			return;
		}
		let formValues = _mapPropertiesFromServiceObjToLocal(editedItem);
		console.log('setting form values from service obj', formValues);
		form.setFieldsValue(formValues);
	}, [editedItem]);

	/*useEffect( () => {
		if (isNew && editedItem && editedItem._id) {
			navigate(getAbsoluteURL(`/users/${editedItem._id}/edit`), { replace: true });
		}
	}, [isNew, editedItem]);*/

	const _mapPropertiesFromServiceObjToLocal = (editedItem) => {
		let formValues = {};
		SERVICE_TO_LOCAL_PROPERTIES_MAPPER.forEach( mapperConf => {
			if (mapperConf.mapper) {
				mapperConf.mapper(editedItem, formValues);
			}
			else {
				formValues[mapperConf.key] = editedItem[mapperConf.key];
			}
		});
		return formValues;
	}

	const _mapPropertiesFromLocalObjToService = (values) => {
		let serviceObj = {};
		LOCAL_TO_SERVICE_PROPERTIES_MAPPER.forEach( mapperConf => {
			if (mapperConf.mapper) {
				mapperConf.mapper(values, serviceObj);
			}
			else {
				if (values.hasOwnProperty(mapperConf.key)) {
					serviceObj[mapperConf.key] = values[mapperConf.key];
				}
			}
		});
		return serviceObj;
	}

	const fetchResourceAndUpdate = () => {
		userStore.get(authStore.id).then( result => {
			console.log('got resource', result);
			setEditedItem( toJS(result) );
		}).catch( err => {
			setEditedItemError(err.message);
		}).then( () => {
			miscMgmtStore.get(authStore.id, {
				query: {
					action: 'uid-card'
				}
			}).then( (cardSummary) => {
				setCardSummary(cardSummary);
			});
		});
	}

	const onFormFinish = (values) => {
		console.log(values);
		let serviceObj = _mapPropertiesFromLocalObjToService(values);
		if (serviceObj.hasOwnProperty('password') && !serviceObj.password) {
			delete serviceObj.password;
		}
		if (!isNew) {
			delete serviceObj.email;
		}
		if (!serviceObj.password) {
			//no need to update anything
			console.log('skipping update, nothing to do');
			notification.info({
				message: 'Info'
				,description: 'Niente da modificare!'
				,duration: 4
			});
			return;
		}
		serviceObj = _.pick(serviceObj, ['password']);
		setIsSubmitting(true);
		let promise = Promise.resolve();
		if (serviceObj.password && !isNew) {
			promise = new Promise( (resolve, reject) => {
				Modal.confirm({
					title: 'Conferma',
					content: <div><p>Hai scelto di reimpostare la password. Sei sicuro?</p><p>Se hai cambiato idea puoi premere su "Annulla" e lasciare in bianco il campo password per tenere quella attuale.</p></div>,
					okText: 'Sì',
					cancelText: 'Annulla',
					onOk: () => {
						resolve();
					},
					onCancel: () => {
						reject(new Error('user-cancelled'));
					}
				});
			});
		}
		if (!isNew) {
			const id = editedItem._id;
			promise = promise.then( () => userStore.patch(id, serviceObj) );
		}
		else {
			promise = promise.then( () => userStore.create(serviceObj) );
		}
		promise.then( (savedItem) => {
			notification.success({
				message: 'Ok!'
				,description: `L'utente è stato aggiornato con successo`
				,duration: 5
			});
			setEditedItem( toJS(savedItem) );
			setTimeout( () => { setIsSubmitting(false) }, 2500);
		}).catch(err => {
			if (err.message != 'user-cancelled') {
				notification.error({
					message: 'Errore'
					,description: `Non è stato possibile salvare l'utente: ${err.message || JSON.stringify(err)}`
					,duration: 8
				});
			}
			setIsSubmitting(false);
		});
	};

	const deleteResource = () => {
		console.log('delete resource');
		userStore.remove(editedItem._id).then( result => {
			notification.success({
				message: 'Ok!'
				,description: `L'utente è stato eliminato con successo. Vado alla lista degli utenti...`
				,duration: 5
			});
			setTimeout( () => {
				navigate(getAbsoluteURL(`/users`), { replace: true });
			}, 1000)
		}).catch( err => {
			notification.error({
				message: 'Errore'
				,description: `Non è stato possibile eliminare l'utente: ${err.message || JSON.stringify(err)}`
				,duration: 8
			});
		});
	}

	const downloadQRCode = () => {
		let svg = document.querySelector(`.qrcode-${editedItem._id}`);
		if (!svg){
			console.error('Cannot find svg');
			return;
		}
		var serializer = new XMLSerializer();
		var svgData = serializer.serializeToString(svg);
		//add name spaces.
		if(!svgData.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
			svgData = svgData.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
		}
		if(!svgData.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
			svgData = svgData.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
		}
		//add xml declaration
		svgData = '<?xml version="1.0" standalone="no"?>\r\n' + svgData;
		let svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
		saveAs(svgBlob, `tessera-zic-${editedItem.email.replace(/[@+.]/g, '_')}.svg`);
	}

	if (!isNew && !editedItem) {
		if (editedItemError) {
			return <Result status="error" title="Errore"
				subTitle={<div>
					Impossibile recuperare la risorsa richiesta<br/>Errore: {editedItemError}
					</div>}
				/>
		}
		return <Skeleton />
	}

	return (
	<Form name="user-edit" labelCol={LABEL_COL} wrapperCol={WRAPPER_COL} form={form}
	 	scrollToFirstError={true}
                onFinish={onFormFinish} layout="vertical"
        >
		{isNew ? 
			(<Form.Item label="Email" name="email" rules={[{ type: 'email' }]}>
				<Input disabled={isNew ? false : true} type="email" />
			</Form.Item>)
			: (<Form.Item label="Email">
				<Typography>
					<pre className='mt-0 mb-0'>{emailValue}</pre>
				</Typography>
			</Form.Item>)
		}
		<ScrollToTopOnMount />
		<Form.Item label="Password" name="password"
			rules={[ ...(isNew ? [ { required: true, message: 'La password è obbligatoria per i nuovi utenti' }] : []) , { validator: validatePassword }]}
			extra={isNew ? null : "Lascia in bianco se non vuoi modificare la tua password"}
		>
			<Input type="password" autoComplete="new-password" allowClear />
		</Form.Item>
		<Form.Item label="Nome" name="firstName">
			<Typography><pre className='mt-0 mb-0'>{editedItem?.firstName || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Cognome" name="lastName">
			<Typography><pre className='mt-0 mb-0'>{editedItem?.lastName || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Sesso" name="sex">
			<Radio.Group options={DEFS.SEX_OPTIONS} disabled  />
		</Form.Item>
		<Form.Item label="Telefono (cellulare)" name="phone" >
			<Typography><pre className='mt-0 mb-0'>{editedItem?.phone || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Telefono (fisso)" name="landlinePhone" >
			<Typography><pre className='mt-0 mb-0'>{editedItem?.landlinePhone || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Data di nascita" name="birthdate" >
			<Typography><pre className='mt-0 mb-0'>{editedItem?.birthdate ? moment(editedItem.birthdate).format('YYYY-MM-DD') : ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Luogo di nascita" name="birthplace" >
			<Typography><pre className='mt-0 mb-0'>{editedItem?.birthplace || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Codice fiscale" name="fiscalCode">
			<Typography><pre className='mt-0 mb-0'>{editedItem?.fiscalCode || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Indirizzo (con civico)" name={['address','street']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.address?.street || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Città" name={['address','city']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.address?.city || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="CAP" name={['address','zip']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.address?.zip || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Provincia (sigla)" name={['address','province']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.address?.province || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Scadenza cert. medico" name="medicalCertExpDate" >
			<Typography><pre className='mt-0 mb-0'>{editedItem?.medicalCertExpDate ? moment(editedItem.medicalCertExpDate).format('YYYY-MM-DD') : ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="# Iscrizione NSI" name={['nsiMembership', 'number']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.nsiMembership?.number || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Scadenza iscrizione NSI" name={['nsiMembership', 'expDate']}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.nsiMembership?.expDate ? moment(editedItem.nsiMembership.expDate).format('YYYY-MM-DD') : ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Nome Genitore" name={['parent', 'firstName']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.parent?.firstName || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Cognome Genitore" name={['parent','lastName']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.parent?.lastName || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Data di nascita Genitore" name={['parent','birthdate']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.parent?.birthdate ? moment(editedItem.parent.birthdate).format('YYYY-MM-DD') : ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Luogo nascita Genitore" name={['parent','birthplace']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.parent?.birthplace || ' '}</pre></Typography>
		</Form.Item>
		<Form.Item label="Sesso Genitore" name={['parent','sex']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Radio.Group options={DEFS.SEX_OPTIONS} disabled />
		</Form.Item>
		<Form.Item label="Codice fiscale Genitore" name={['parent','fiscalCode']} extra={isUnderageUser ? "L'utente è minorenne" : null}>
			<Typography><pre className='mt-0 mb-0'>{editedItem?.parent?.fiscalCode || ' '}</pre></Typography>
		</Form.Item>
		{/*<Form.Item label="Note" name="notes" >
			<Input.TextArea placeholder="note varie..." rows={3} />
		</Form.Item>
		<Form.Item label={<span>Consenti <strong>sempre</strong> l'accesso a questi tornelli a questo utente</span>}
			name="alwaysGrantedTurnstiles" className='mt-30'
			help="Altrimenti, l'accesso sarà regolato dai giorni impostati per i singoli corsi/abbonamenti cui è iscritto l'utente"
		>
			<Checkbox.Group options={DEFS.turnstilesCheckboxGroup} />
		</Form.Item>
		<Form.Item label={<span>Permessi avanzati. Attivare solo per gli utenti <strong>amministratori</strong>!</span>}
			name="permissions" className='mt-30'
		>
			<Checkbox.Group options={DEFS.userPermissionsCheckboxGroup} />
		</Form.Item>*/
		}
		{!isNew &&
		<Form.Item label='QRCode Tessera ZIC' className='mt-30 qrcode-container'>
			{ fullQRCode ? <>
					<div><QRCodeSVG size={500} includeMargin={true} className={`qrcode-${editedItem._id} qrcode-svg`} data-card-code={editedItem.cardCode} value={fullQRCode} /></div>
					<Button type="dashed" size="small" onClick={downloadQRCode}>scarica</Button>
				</>
				: <Alert message="QRCode non generato / mancante" type="error" />
			}
		</Form.Item>
		}
		{!isNew &&
		<Form.Item label="Tessera #">
			<Typography>
				<pre className='mt-0 mb-0'>{cardSummary?.cardSeqNo || '---'}</pre>
			</Typography>
		</Form.Item>
		}
		<Form.Item>
			<Space className='mt-30'>
				<Button type="primary" htmlType="submit" disabled={isSubmitting}>Aggiorna</Button>
			</Space>
		</Form.Item>
	</Form>
	);
};

export default UserEdit;

