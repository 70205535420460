//import Settings from './pages/Settings';
import UserCard from './pages/UserCard';
import UserEdit from './pages/UserEdit';
import UserDocuments from './pages/UserDocuments';
import Subscriptions from './pages/Subscriptions';
import { UserOutlined, ApartmentOutlined, QrcodeOutlined, FileTextOutlined } from '@ant-design/icons';

const ROUTES = [
	{ path: '/', routerComponent: UserCard, label: 'Tessera ZIC', key: 'customer-card', icon: <QrcodeOutlined /> }
	,{ path: '/subscriptions', routerComponent: Subscriptions, label: 'Iscrizioni', key: 'subscriptions', icon: <ApartmentOutlined /> }
	,{ path: '/me', routerComponent: UserEdit, label: 'Il mio utente', key: 'user-me', icon: <UserOutlined /> }
	,{ path: '/documents', routerComponent: UserDocuments, label: 'Ricevute/documenti', key: 'user-docs', icon: <FileTextOutlined /> }
];

export default ROUTES;
