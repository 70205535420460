import React, { useState, useMemo, useContext } from 'react';
import { Routes, Route, Link, matchPath, useLocation } from "react-router-dom";
import { Layout, Menu, Result, Breadcrumb, Grid, Affix, Button, TriggerIconComponent } from 'antd';
import {  ShoppingCartOutlined, ScanOutlined, SearchOutlined, ContainerOutlined
	,MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LogoutOutlined
} from '@ant-design/icons';
import logo from '../logo.png';
import Config from '../Config';
import { useAuthStore } from '../hooks/use-stores'
import requiresAuthentication from '../common/requiresAuthentication';
import MainHeader  from './Header';
import { getAbsoluteURL, stripBaseURL } from '../common/common';
import isMobileChecker from 'is-mobile';

import BG0 from '../images/login_full_bg0.jpg';
import BG1 from '../images/login_full_bg1.jpg';
import BG2 from '../images/login_full_bg2.jpg';

import MENU from '../routes';

const { useBreakpoint } = Grid;

const SIMPLE_LAYOUT_BGS = [BG0, BG1, BG2];


/*
	<Router>
		<Products path="/" />
		//<ProductEdit path="/dashboards/:id/edit" />
		//<Users path="/users" />
		//<UserEdit path="/users/:id/edit" />
		//<UserEdit path="/users/new" />
		<NotFound default />
	</Router>
*/

function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const withSimpleLayout = (WrappedComponent, opts) => {
	const year = new Date().getFullYear();
	opts = opts || {};
	return (props) => {
		return (
			<Layout style={{ minHeight: '100vh' }} className='simple-layout'>
				{!opts.noBg && <img src={SIMPLE_LAYOUT_BGS[getRandomInt(0, SIMPLE_LAYOUT_BGS.length)]} className='full-bg animation-pulseSlow' />}
				<Layout.Content className='z-10'>
					<WrappedComponent {...props} />
				</Layout.Content>
				<Layout.Footer className='z-10'>
					Copyright © {year} ZeroInCondottA. All rights reserved
				</Layout.Footer>
			</Layout>
		);
	}
}

const MainLayout = requiresAuthentication( (props) => {
	const authStore = useAuthStore();
	const location = useLocation();
	const isMobile = isMobileChecker();
	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
	const menu = MENU;
	const menuItems = useMemo( () => {
		let items = menu.map( i => ({
			key: i.key
			,icon: i.icon
			,label: (<Link to={getAbsoluteURL(i.path)}>{i.label}</Link>)
		}));
		/*items.push({
			type: 'divider'
		})
		items.push({
			key: 'user-submenu'
			,icon: <UserOutlined />
			,title: authStore.name ? authStore.name : authStore.email
			,label: authStore.name ? authStore.name : authStore.email
			,children: [
				{ key: 'logout', icon: <LogoutOutlined />, label: 'Logout' }
			]
		});*/
		return items;
	}, [menu]);
	const screens = useBreakpoint();
	const collapsedWidth = screens.md ? 80 : 0;

	function onMenuClick({ item, key, keyPath }) {
		if (key === 'logout') {
			logout();
			return;
		}
		if (keyPath && keyPath.slice(-1) == 'user-submenu') {
			return;
		}
		collapseSidebarOnMobile();
	}

	function logout() {
		authStore.logout();
	}

	function getBreadcrumb() {
		const pathArr = getMenuItemsPath();
		return (
		<Breadcrumb>
			<Breadcrumb.Item>Home</Breadcrumb.Item>
			{pathArr.map( (pathItem, pathIndex) => (
			<Breadcrumb.Item key={pathIndex}>
				<Link to={getAbsoluteURL(pathItem.path)}>{pathItem.label}</Link>
			</Breadcrumb.Item>
			) )}
		</Breadcrumb>
		);
	}

	function getMenuItemsPath() {
		let pathArr = [];
		menu.find( (menuItem) => {
			//console.log(location.pathname);
			let hasMatch = !! matchPath(getAbsoluteURL(menuItem.path), location.pathname);
			if (hasMatch) {
				pathArr.push({ ...menuItem, path: stripBaseURL(location.pathname) });
			}
			else if (menuItem.children) { //no match but with children...
				let foundItem = menuItem.children.find( (subItem) => !!matchPath(getAbsoluteURL(subItem.path), location.pathname) );
				hasMatch = foundItem ? true : false;
				if (hasMatch) {
					pathArr.push(menuItem, { ...foundItem, path: stripBaseURL(location.pathname) });
				}
			}
			return hasMatch;
		});
		return pathArr;
	}

	function getMenuSelectedKeys() {
		const pathArr = getMenuItemsPath();
		if (pathArr[0]) {
			return [ pathArr[0].key ];
		}
		/*
		return menu.filter( (menuItem) => {
			let hasMatch = !! match(getAbsoluteURL(menuItem.path), location.pathname);
			if (!hasMatch && menuItem.children) {
				let found = menuItem.children.find( (subItem) => !!match(getAbsoluteURL(subItem.path), location.pathname) );
				hasMatch = found ? true : false;
			}
			return hasMatch;
		}).map( (menuItem) => menuItem.key);
		*/
	}

	function toggleSidebar() {
		setSidebarCollapsed( !sidebarCollapsed );
	}

	function onSidebarCollapsed(collapsed){
		setSidebarCollapsed(collapsed);
	}

	function collapseSidebarOnMobile() {
		if (isMobile) {
			setSidebarCollapsed(true);
		}
	}

	function Div(props) {
		return <div>{props.children}</div>;
	}

	const TriggerIconComponent = sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
	const AffixOrNot = window.innerHeight < 500 ? Div : Affix;
	const otherProps = screens.md ? null : { trigger: null };

	return (
	<Layout style={{ minHeight: '100vh' }}>
		<Layout.Sider theme="dark" collapsible {...otherProps} collapsed={sidebarCollapsed} collapsedWidth={collapsedWidth} onCollapse={onSidebarCollapsed} breakpoint='md'>
			<AffixOrNot offsetTop={0}>
				<div>
					<div className="sider-menu-logo">
						<img src={logo} className="logo" alt="logo" />
					</div>
					<Menu theme="dark" className='sider-menu' mode="inline" selectedKeys={getMenuSelectedKeys()} onClick={onMenuClick} items={menuItems} />
				</div>
			</AffixOrNot>
		</Layout.Sider>
		<Layout>
			<Layout.Header className="app-header ant-menu-dark">
				<MainHeader sidebarCollapsed={sidebarCollapsed} toggleSidebar={toggleSidebar} />
			</Layout.Header>
			<Layout.Content>
				{ getBreadcrumb() }
				<div className="main-panel">
					<Routes>
						{menu.flatMap( parentMenuItem => (
							[ parentMenuItem, ...(parentMenuItem.children || []) ].map( menuItem =>
								<Route path={menuItem.path} key={menuItem.path} element={
									<menuItem.routerComponent {...menuItem.routerExtraProps} />
								} />
							)
						) ) }
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</Layout.Content>
		</Layout>
	</Layout>);
} );

function NotFound() {
        return (
                <Result status="404" title="404" subTitle="La pagina richiesta non esiste" />
        );
}

export { withSimpleLayout, MainLayout, NotFound };
