import { makeAutoObservable, observable, computed, action } from "mobx";

class AuthStore {
	id;
	email;
	firstName;
	lastName;
	cardCode;
	permissions = [];

	isAuthenticated = null;
	_isLoggingIn = false;

	rootStore;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
			,reAuthenticate: action
			,login: action
		});
		this.rootStore = rootStore;
	}

	setUser(user) {
		console.log('setting user', user);
		let props = [{ key: '_id', mapped: 'id' }, 'email', 'firstName', 'lastName', 'permissions', 'cardCode'];
		props.forEach( prop => {
			let { key, mapped } = prop instanceof Object ? prop : { key: prop, mapped: prop };
			if (Object.prototype.hasOwnProperty.call(user, key)) {
				this[mapped] = user[key];
			}
		});
	}

	reAuthenticate() {
		this._isLoggingIn = true;
		return this.rootStore.app.reAuthenticate().then( action(data => {
			console.log('re-auth: ok');
			if (data.user) {
				this.setUser(data.user);
			}
			this.isAuthenticated = true;
		})).catch(action((err) => {
			console.log('re-auth: failed', err);
			this.isAuthenticated = false;
		})).then( action( () => {
			this._isLoggingIn = false;
		}));
	}

	login(email, password) {
		this._isLoggingIn = true;
		console.log('logging in');
		let errObj = null;
		return this.rootStore.app.authenticate({
			strategy: 'local'
			,email
			,password
		}).then( action(data => {
			if (data.user) {
				this.setUser(data.user);
			}
			this.isAuthenticated = true;
			console.log('login successful');
		})).catch( action(err => {
			this.isAuthenticated = false;
			console.warn('login failed');
			errObj = err;
		})).then( action(() => {
			this._isLoggingIn = false;
			if (errObj) {
				throw errObj;
			}
		}));
	}

	signup(data) {
		return this.rootStore.services.users.create({
			email: data.email
			,password: data.password
			,firstName: data.firstName
			,lastName: data.lastName
		});
	}

	logout() {
		return this.rootStore.app.logout().then( action(() => {
			this.isAuthenticated = false;
		}));
	}

	verifySignup(verifyToken) {
		return this.rootStore.services.authManagement.create({
			action: "verifySignupLong"
			,value: verifyToken
		});
	}
	
	resendVerifySignup(email) {
		return this.rootStore.services.authManagement.create({
			action: "resendVerifySignup"
			,value: { email }
		});
	}

	sendPasswordResetEmail(email, opts) {
		opts = opts || null;
		return this.rootStore.services.authManagement.create({
			action: 'sendResetPwd'
			,value: { email }
			,notifierOptions: { source: 'client', ...opts }
		});
	}

	confirmPasswordReset(token, password) {
		return this.rootStore.services.authManagement.create({
			action: 'resetPwdLong'
			,value: {
				token
				,password
			}
		});
	}
}

export default AuthStore;
