import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Spin, Typography, Form, Input, Button, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';

import { withSimpleLayout } from '../layouts/Layouts';
import { getAbsoluteURL, validatePassword } from '../common/common';

const PasswordReset = withSimpleLayout( (props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useAuthStore();
	const navigate = useNavigate();
	const params = useParams();

	useEffect( () => {
		if (!params.tokenId) {
			notification.error({
				message: 'Errore'
				,description: 'Reset link errato: manca il token nell\'url'
				,duration: 8
			});
		}
		if (isSubmitted || !params.tokenId){
			navigate(getAbsoluteURL('/auth/login'));
		}
	}, [isSubmitted, params.tokenId]);

	function onSubmit(values) {
		setSubmitting(true);
		authStore.confirmPasswordReset(params.tokenId, values.password).then( () => {
			notification.success({
				message: 'Operazione eseguita'
				,description: 'La password è stata reimpostata con successo. Puoi autenticarti adesso'
				,duration: 8
			});
			setIsSubmitted(true);
		}).catch( (err) => {
			notification.error({
				message: 'Errore'
				,description: `Impossibile reimpostare la password. Prova ancora, grazie. Errore: ${err.message}`
				,duration: 8
			});
		}).then( () => {
			setSubmitting(false);
		});
	}

	if (isSubmitted || !params.tokenId){
		return <div>Reindirizzamento...</div>
	}

	return (<div className="password-reset-form-container page-form-container">
		<Typography.Title level={2} className="text-center">ZeroInCondottA Reset Password</Typography.Title>
		<PasswordResetForm store={authStore} onSubmit={onSubmit} submitting={submitting} />
	</div>);
} );

const PasswordResetForm = (props) => {
	function handleFailedSubmit(errorInfo) {
		console.log(errorInfo);
	}
	function handleSubmit(values) {
		props.onSubmit(values);
	}

	const getPasswordConfirmationValidator = ({ getFieldValue }) => ({
		validator: (_, value) => {
			if (!value || getFieldValue('password') === value) {
				return Promise.resolve();
			}

			return Promise.reject(new Error('Le due password non coincidono!'));
		}
	});

	return (
		<Form name='password-reset-form' className="password-reset-form" layout='vertical' onFinish={handleSubmit} onFinishFailed={handleFailedSubmit}>
			<Form.Item name='password' label='Password'
				rules={ [ { required: true, message: 'Please insert the new password' }, { validator: validatePassword } ] }
			>
				<Input type="password" prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
					placeholder="password" />
			</Form.Item>
			<Form.Item name='password-confirm' label='Conferma password' dependencies={['password']}
				rules={ [ { required: true, message: 'Per favore conferma la password' }
					 ,getPasswordConfirmationValidator
				] }
			>
				<Input type="password" prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
					placeholder="password" />
			</Form.Item>
			<Form.Item>
				<Link to={getAbsoluteURL('/auth/login')}>Vai al Login</Link>
			</Form.Item>
			<Form.Item>
				<Button className="submit ant-btn-block" type="primary" htmlType="submit" disabled={props.submitting}>Reimposta password</Button>
			</Form.Item>
		</Form>
	);
};

export default PasswordReset;
