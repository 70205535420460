import { makeObservable, observable, action } from 'mobx';

import AuthStore from './auth-store';
import CourseStore from './course-store';
import UserStore from './user-store';
import SubscriptionStore from './subscription-store';
import MiscMgmtStore from './miscmgmt-store';

import FeathersInstance from '../common/feathers';

class RootStore {
	constructor() {
		this.app = FeathersInstance.app;
		this.services = FeathersInstance.services;

		this.authStore = new AuthStore(this);
		this.courseStore = new CourseStore(this);
		this.userStore = new UserStore(this);
		this.subscriptionStore = new SubscriptionStore(this);
		this.miscMgmtStore = new MiscMgmtStore(this);

		this.authStore.reAuthenticate();

	}
}

//this is a singleton
const RootStoreInstance = new RootStore();

export default RootStoreInstance;
