import { useState } from 'react';
import { Form, Input, Modal } from 'antd';

function ModalPromptConfirm(props) {
	//{ title, message, onConfirm, onCancel, validator, placeholder, validationErrorMessage }
	const [modalVisible, setModalVisible] = useState(false);
	const [form] = Form.useForm();
	
	const onClick = (e) => {
		form.resetFields();
		setModalVisible(true);
	}

	const handleOk = () => {
		form.submit();
	}
	const handleCancel = () => {
		setModalVisible(false);
		if (props.onCancel) {
			props.onCancel();
		}
	}

	const onFormFinish = (values) => {
		props.onConfirm();
		setModalVisible(false);
	}

	const customValidator = (rule, value) => {
		if (!value || props.validator(value)) {
			return Promise.resolve();
		}
		else {
			return Promise.reject( new Error(props.validationErrorMessage || 'La conferma non corrisponde a quella attesa') );
		}
	}

	return <>
		<div onClick={onClick}>{props.children}</div>
		<Modal title={props.title} visible={modalVisible} onOk={handleOk} onCancel={handleCancel}>
			<div className="mb-15">{props.message}</div>
			<Form form={form} onFinish={onFormFinish}>
				<Form.Item name="prompt" rules={[ { required: true, message: 'Devi riempire questo campo' }, { validator: customValidator } ]}>
					<Input type="text" placeholder={props.placeholder || null} />
				</Form.Item>
			</Form>
		</Modal>
	</>
}

export default ModalPromptConfirm;
