import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import { toJS } from 'mobx';
import { useMiscMgmtStore, useAuthStore } from '../hooks/use-stores';
import Defs from '../common/defs';
import { observer } from 'mobx-react-lite';
import { Space, Button, Spin, Grid, notification, Modal, Alert, Skeleton, Card, Typography, Result } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, LoadingOutlined, PrinterOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import { saveAs } from 'file-saver';
import logo from '../logo.png';
import { getFullQRCode } from '../common/all-ui-common';

import ScrollToTopOnMount from '../ScrollToTopOnMount';

const { useBreakpoint } = Grid;

const UserCard = observer( (props) => {
	const authStore = useAuthStore();
	const miscMgmtStore = useMiscMgmtStore();
	const [cardSummary, setCardSummary] = useState();
	const [cachedMode, setCachedMode] = useState(false);
	const [loadError, setLoadError] = useState(null);
	const screens = useBreakpoint();
	const fullQRCode = useMemo( () => {
		let usableCardCode = null;
		if (authStore && authStore.cardCode) {
			usableCardCode = authStore.cardCode;
			//saving the code so that we can get it when no there is no internet connection
			localStorage.setItem('zic:cardCode', authStore.cardCode);
		}
		else {
			usableCardCode = localStorage.getItem('zic:cardCode');
			if (!usableCardCode) {
				return null;
			}
			setCachedMode(true);
		}
		return getFullQRCode(usableCardCode);
	}, [authStore]);

	useEffect( () => {
		reloadUidCard();
	}, [authStore.id]);

	const reloadUidCard = () => {
		if (!authStore.id) {
			tryCachedUidCardIfExists();
			return;
		}
		miscMgmtStore.get(authStore.id, {
			query: {
				action: 'uid-card'
			}
		}).then( (cardSummary) => {
			if (cachedMode) {
				setCachedMode(false);
			}
			setLoadError(null);
			localStorage.setItem('zic:uidCard', JSON.stringify(cardSummary));
			setCardSummary(cardSummary);
		}).catch( err => {
			console.error(err);
			setLoadError(err.message);
			tryCachedUidCardIfExists();
		});
	}

	const tryCachedUidCardIfExists = () => {
		let uidCard = localStorage.getItem('zic:uidCard');
		if (!uidCard) {
			return;
		}
		try {
			uidCard = JSON.parse(uidCard);
		} catch(err) {
			console.error(err);
			return;
		}
		setCachedMode(true);
		showCachedCardWarning();
		setCardSummary(uidCard);
	}

	const showCachedCardWarning = () => {
		Modal.warning({
			title: 'Attenzione'
			,content: <div>
				<p>Non riesco a scaricare le informazioni sulla tua tessera.</p>
				<p>Potrebbe esserci un problema con la connessione ad internet o un momentaneo disservizio dei nostri sistemi.<br/>
				Provo ad utilizzare l'ultima versione salvata della tessera, tieni presente che le informazioni <strong>potrebbero non essere aggiornate</strong>!
				</p>
			</div>
		});
	}

	const downloadQRCode = () => {
		let svg = document.querySelector(`.qrcode-${authStore.id}`);
		if (!svg){
			console.error('Cannot find svg');
			return;
		}
		var serializer = new XMLSerializer();
		var svgData = serializer.serializeToString(svg);
		//add name spaces.
		if(!svgData.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
			svgData = svgData.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
		}
		if(!svgData.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
			svgData = svgData.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
		}
		//add xml declaration
		svgData = '<?xml version="1.0" standalone="no"?>\r\n' + svgData;
		let svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
		saveAs(svgBlob, `tessera-zic-${authStore.email.replace(/[@+.]/g, '_')}.svg`);
	}

	if (!cardSummary) {
		if (loadError) {
			return <>
				<Alert message="Errore durante lo scaricamento dei dati. Vuoi riprovare?"
					type="warning"
					action={
						<Space>
						<Button size="small" type="ghost" onClick={reloadUidCard}>Ricarica</Button>
						</Space>
					}
					/>
				<Result status="error" title="Errore"
				subTitle={<div>
					Impossibile recuperare la risorsa richiesta<br/>Errore: {loadError}
					</div>}
				/>
			</>
		}
		return <Skeleton active />;
	}

	const userName = cardSummary && cardSummary.userObj instanceof Object && cardSummary.userObj.firstName && cardSummary.userObj.lastName ?
		`${cardSummary.userObj.firstName} ${cardSummary.userObj.lastName}`
		: (authStore ?
			(authStore.firstName && authStore.lastName ? `${authStore.firstName} ${authStore.lastName}` : authStore.email)
			: '--- n/a ---'
		);

	const getCardDescription = () => {
		const isStaffMember = cardSummary.userObj instanceof Object && cardSummary.userObj.staffMember;
		const staffMemberBlock = isStaffMember ? <div>Questo utente è un membro dello staff</div> : null;
		const cachedModeBlock = cachedMode ?
			<div className="mb-10">
				<Typography.Text type='danger'>
				Mostro una versione precedentemente salvata della tessera. Attenzione, le informazioni potrebbero non essere aggiornate
				</Typography.Text>
			</div>
			: null;

		if (cardSummary.cardExp) {
			const isExpired = moment(cardSummary.cardExp).isBefore(new Date(), 'day');
			return (<div>
				{cachedModeBlock}
				Scadenza: <Typography.Text type={isExpired ? 'danger' : 'secondary'}>{moment(cardSummary.cardExp).format('YYYY-MM-DD')}</Typography.Text>
				{cardSummary?.cardSeqNo &&
					<div>Tessera #{cardSummary.cardSeqNo}</div>
				}
				{isStaffMember && <div className="mt-10 text-small">
					{staffMemberBlock}
				</div>}
			</div>);
		}
		//we don't have the cardExp
		if (isStaffMember) {
			return <>
				{cachedModeBlock}
				{staffMemberBlock}
			</>;
		}
		//the user is not a staff member and has no subscription
		return (<div>
			{cachedModeBlock}
			<Typography.Text type="danger">Tessera inattiva</Typography.Text>
			<div className="text-small">Non risultano iscrizioni attive in questo momento</div>
		</div>);
	};

	return <div>
		<ScrollToTopOnMount />
		{cachedMode && 
		<Alert message="Impossibile scaricare le informazioni relative alla tua tessera, ti mostro l'ultima versione salvata."
			type="warning"
			action={
				<Space>
				<Button size="small" type="ghost" onClick={reloadUidCard}>Ricarica</Button>
				</Space>
			}
			/>
		}
		<Card className='zic-card' cover={fullQRCode ?
				<QRCodeSVG size={500} includeMargin={true} className={`qrcode-${authStore.id} qrcode-svg`} data-card-code={authStore.cardCode} value={fullQRCode} />
				: <Alert message="QRCode non generato / mancante" type="error" />
		}>
			<Card.Meta title={userName} description={getCardDescription()} />
			<div className="text-right mt-30 mb-30">
				<Button type="dashed" size="small" onClick={downloadQRCode}>scarica QR Code</Button>
			</div>
			<div className="card-footer-unpadded">
				<img src={logo} alt="logo" />
			</div>
		</Card>
	</div>
});

export default UserCard;
