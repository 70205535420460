import React from 'react';
import Config from '../Config';
import moment from 'moment';
import Defs from './defs';
import _ from 'lodash';
import { Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
  
const mountPointNormalized = Config.MOUNT_POINT.replace(/\/$/, '');
const mountPointNormalizedRegexp = new RegExp(`^${mountPointNormalized}`);

const FISCALCODE_RE = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;

function cleanPath(path) {
	return path.replace(/\/+/g, '/');
}

function getAbsoluteURL(path) {
	path = path.indexOf('/') === 0 ? path : `/${path}`;
        return `${mountPointNormalized}${path}`;
}
function stripBaseURL(path) {
        return path.replace(mountPointNormalizedRegexp, '');
}

const validatePassword = (rule, value) => {
        const DIGIT_RE = /\d/g, UPPERCASE_RE = /[A-Z]/g, LOWERCASE_RE = /[a-z]/g, SPECIAL_CHARS_RE = /[^A-Za-z0-9]/;
        if (value &&
                ( value.length < 8 || !value.match(DIGIT_RE) || !value.match(SPECIAL_CHARS_RE)
                  || !value.match(UPPERCASE_RE) || !value.match(LOWERCASE_RE) )
        ) {
		return Promise.reject(
			new Error('La password deve essere lunga almeno 8 caratteri e deve contenere un numero, una lettera maiuscola e una minuscola ed un carattere speciale')
		);
        }
        return Promise.resolve();
};

const getIfAmountGreaterThanZeroValidator = (amountField) => (
	({ getFieldValue }) => ({
		validator: (rule, value) => {
			let amountVal = getFieldValue(amountField);
			if ( amountVal > 0 ? value : true ) { //validates when amountVal > 0 and we have a choice or when amountVal <= 0 or is empty
				return Promise.resolve();
			}
			return Promise.reject(new Error('Errore di validazione'));
		}
		,message: 'Devi inserire la tipologia di pagamento quando è valorizzato il campo importo'
	})
);

const validateFiscalCode = (rule, value) => {
	if (value && !value.match(FISCALCODE_RE)) {
		return Promise.reject(
			new Error('Il codice fiscale non è nel formato corretto')
		);
	}
	return Promise.resolve();
}

const getExtendedCourseName = i => {
	return `${i.name} - ${i.location} (${i.teachers ? i.teachers + ' - ' : ''}${moment(i.dateStart).format('YYYY')}/${moment(i.dateEnd).format('YY')})`;
}

const getMapSubscriptionObjFn = (comparisonDate, opts = {}) =>{
	return (item) => {
		let courseTxt = item.courseId instanceof Object ? getExtendedCourseName(item.courseId) : `ID: ${item.courseId}`;

		let userTxt = item.userId instanceof Object ? `${item.userId.lastName} ${item.userId.firstName} (${item.userId.email})` : `ID: ${item.userId}`;
		/*let userNode = item.userId instanceof Object ?
			<React.Fragment>{item.userId.lastName} {item.userId.firstName} <small>({item.userId.email})</small></React.Fragment>
			: <span>ID: {item.userId}</span>;
		*/
		let userMedicalCertOk = item.userId instanceof Object && item.userId.medicalCertExpDate ?
			moment(item.userId.medicalCertExpDate).isAfter(comparisonDate, 'day') : false
		;
		let extraInfo = {};
		if (opts.extraCourseInfo) {
			let courseObj = item.courseId instanceof Object ? item.courseId : null;
			extraInfo = {
				...extraInfo
				,_courseWeekdaysTxt: courseObj ? 
					(courseObj.courseWeekdays || []).map(i => {
						let found = Defs.weekDaysOptions.find(opt => opt.value == i);
						return found ? found.label : i;
					}).join('/')
					: null
				,courseTimesTxt: courseObj ? courseObj.courseTimesTxt : null
			}
			if (extraInfo._courseWeekdaysTxt || extraInfo.courseTimesTxt) {
				extraInfo._courseWeekdaysAndTimesTxt = `${extraInfo._courseWeekdaysTxt || '---'}, ${extraInfo.courseTimesTxt || '---'}`;
			}
		}

		let dateRef = item.endDate;
		let expTxt = moment(dateRef).format('YYYY-MM-DD');
		let expired = dateRef ? moment(dateRef).isBefore(comparisonDate, 'day') : false;

		let userId = getIdFromPopulatedObjOrObjectId(item.userId);

		let grantedTurnstilesTxt = item.alwaysGrantedTurnstiles ? item.alwaysGrantedTurnstiles.map(i => i.toLowerCase()).join(', ') : null;
		let p2HasAmount = (item.payment2 instanceof Object && item.payment2.amount > 0);
		let p3HasAmount = (item.payment3 instanceof Object && item.payment3.amount > 0);
		let p4HasAmount = (item.payment4 instanceof Object && item.payment4.amount > 0);

		return {
			...item
			,_courseTxt: courseTxt
			,_userTxt: userTxt
			//,_userNode: userNode
			,_userId: userId
			,_expTxt: expTxt
			,_expired: expired 
			,_status: expired ? (item.userInactive ? 'inactive' : 'expired') : null
			,_userMedicalCertStatus: userMedicalCertOk ? null : 'expired'
			,_p2Status: Defs.P2_HAS_STATUS_BY_SUBSCR_TYPE.indexOf(item.type) !== -1 && expired && !p2HasAmount ?
				(item.userInactive ? 'inactive' : 'expired')
				: null
			,_p3Status: Defs.P3_HAS_STATUS_BY_SUBSCR_TYPE.indexOf(item.type) !== -1 && expired && !p3HasAmount ?
				(item.userInactive ? 'inactive' : 'expired')
				: null
			,_p4Status: Defs.P4_HAS_STATUS_BY_SUBSCR_TYPE.indexOf(item.type) !== -1 && expired && !p4HasAmount ?
				(item.userInactive ? 'inactive' : 'expired')
				: null
			,_alwaysGrantedTurnstilesTxt: grantedTurnstilesTxt
			,_extraInfo: extraInfo
		}
	}
}

const getMapUserObjFn = (comparisonDate) => {
	return (item) => (
		{
			...item
			,_lastNameFirstName: `${item.lastName} ${item.firstName}`
			,_medicalCertExpDateTxt: item.medicalCertExpDate ? moment(item.medicalCertExpDate).format('YYYY-MM-DD') : null
			,_medicalCertExpired: item.medicalCertExpDate ? moment(item.medicalCertExpDate).isBefore(comparisonDate, 'day') : false
			,_allPhoneNumbers: `${item.phone || '---'} / ${item.landlinePhone || '---'}`
			,_birthdateTxt: item.birthdate ? moment(item.birthdate).format('YYYY-MM-DD') : null
			,_nsiMembershipExpDate: item.nsiMembership && item.nsiMembership.expDate ? moment(item.nsiMembership.expDate).format('YYYY-MM-DD') : null
			,_parentLastNameFirstName: item.parent && (item.parent.firstName || item.parent.lastName) ?
				`${item.parent.lastName || '[cognome manc.]'} ${item.parent.firstName || '[nome manc.]'}`
				: null
			,_parentBirthdateTxt: item.parent && item.parent.birthdate ? moment(item.parent.birthdate).format('YYYY-MM-DD') : null
		}
	)
}

const getMapCourseObjFn = () => {
	return (item) => (
		{
			...item
			,_dateStartEndTxt: `${moment(item.dateStart).format('YYYY')}/${moment(item.dateEnd).format('YY')}`
			,_courseWeekdaysTxt: (item.courseWeekdays || []).map(i => {
				let found = Defs.weekDaysOptions.find(opt => opt.value == i);
				return found ? found.label : i;
			}).join('/')
			,_turnstilesConfigTxt: item.turnstilesConfig.map(conf => {
				let loc = String(conf.location).toLowerCase();
				let days = conf.weekdays.map(i => {
					let found = Defs.weekDaysOptions.find(opt => opt.value == i);
					return found ? found.label : i;
				}).join('/');
				if (conf.weekdays.length > 0) {
					return `${loc}: ${days}`;
				}
				return null;
			}).join(' - ')
		}
	);
}

const getIdFromPopulatedObjOrObjectId = (recordId) => {
	return recordId instanceof Object ? recordId._id : recordId;
}

const getTableFilterProps = ({ colKeys, searchInputRef, handleSearch, handleReset, setTableSearchState, tableSearchState, noRenderFn, customFilterFn, currKey }) => ({
	filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
		<div style={{ padding: 8 }}>
			<Input
				ref={searchInputRef}
				placeholder={`Cerca...`}
				value={selectedKeys[0]}
				onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
				onPressEnter={(e) => { e.preventDefault(); e.stopPropagation(); handleSearch(selectedKeys, confirm, colKeys); } }
				style={{ marginBottom: 8, display: 'block' }}
			/>
			<Space>
				<Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, colKeys)}
					icon={<SearchOutlined />} size="small" style={{ width: 90 }}
				>Search</Button>
				<Button onClick={() => handleReset(clearFilters, colKeys, confirm, currKey)} size="small" style={{ width: 90 }}>
					Reset
				</Button>
			</Space>
		</div>
	),
	filteredValue: tableSearchState[currKey] instanceof Array && tableSearchState[currKey].length > 0 ? tableSearchState[currKey] : [],
	filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
	onFilter: (value, record) => {
		return colKeys.some( dataIndex => {
			if (customFilterFn) {
				let ret = customFilterFn(dataIndex, value, record);
				if (typeof ret === 'boolean') {
					return ret;
				}
			}
			let val;
			if (dataIndex instanceof Array) {
				let tmpVal = record;
				dataIndex.forEach( (subk, _ndx) => {
					if (_ndx < dataIndex.length - 1) { //not the last element in array, we should search for objects
						if (tmpVal && tmpVal[subk] instanceof Object) {
							tmpVal = tmpVal[subk];
						}
						else {
							tmpVal = null;
						}
					}
					else { //last element in the array, we can search for anything, not just objects
						if (tmpVal && tmpVal instanceof Object && tmpVal.hasOwnProperty(subk)) {
							tmpVal = tmpVal[subk];
						}
						else {
							tmpVal = null;
						}
					}
				});
				if (tmpVal !== null) {
					val = tmpVal;
				}
			}
			else {
				val = record[dataIndex];
			}
			if (val && val.toString().toLowerCase().includes(value.toLowerCase()) ) {
				return true;
			}
			return false;
		});
	},
	onFilterDropdownVisibleChange: visible => {
		if (visible) {
			setTimeout(() => searchInputRef.current.select(), 100);
		}
	},
	render: (noRenderFn ?
		undefined 
		: (text, ...rest) => {
			return (tableSearchState[currKey] instanceof Array && tableSearchState[currKey].length > 0) ? (
				<Highlighter
					highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
					searchWords={[ tableSearchState[currKey][0] ]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			);
		}
	),
});

export {
	getAbsoluteURL
	,stripBaseURL
	,cleanPath
	,validatePassword
	,validateFiscalCode
	,getMapSubscriptionObjFn
	,getIdFromPopulatedObjOrObjectId
	,getMapUserObjFn
	,getMapCourseObjFn
	,getIfAmountGreaterThanZeroValidator
	,getExtendedCourseName
	,getTableFilterProps
};
