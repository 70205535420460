const SINGLE_BLOCKS_SUBSCRIPTION = 'S';
const SINGLE_BLOCKS_PLUS_ONE_SUBSCRIPTION = 'S+1';
const HALF_YEAR_SUBSCRIPTION = 'MA';
const SINGLE_COURSE_FULL_YEAR_SUBSCRIPTION = 'PP';
const MULTI_COURSES_FULL_YEAR_SUBSCRIPTION = 'A';
const DEFS = {
	turnstilesCheckboxGroup: [
		{ label: 'Brescia via Valcamonica', value: 'bs-valcamonica' }
	]

	,userPermissionsCheckboxGroup: [
		{ label: 'Amministratore', value: 'admin' }
	]

	,weekDaysOptions: [
		{ label: 'Lun', value: 1 }
		,{ label: 'Mar', value: 2 }
		,{ label: 'Mer', value: 3 }
		,{ label: 'Gio', value: 4 }
		,{ label: 'Ven', value: 5 }
		,{ label: 'Sab', value: 6 }
		,{ label: 'Dom', value: 0 }
	]

	,SEX_OPTIONS: [
		{ label: 'Maschio', value: 'M' }
		,{ label: 'Femmina', value: 'F' }
	]

	,subscriptionTypes: [
		{ label: 'Singoli blocchi di lezioni', value: SINGLE_BLOCKS_SUBSCRIPTION }
		,{ label: 'Singoli blocchi +1', value: SINGLE_BLOCKS_PLUS_ONE_SUBSCRIPTION }
		,{ label: 'Proprio corso annuale', value: SINGLE_COURSE_FULL_YEAR_SUBSCRIPTION }
		,{ label: 'Abbonamento annuale', value: MULTI_COURSES_FULL_YEAR_SUBSCRIPTION }
		,{ label: 'Abbonamento mezzo anno', value: HALF_YEAR_SUBSCRIPTION }
	]
	,P2_HAS_STATUS_BY_SUBSCR_TYPE: [SINGLE_BLOCKS_SUBSCRIPTION, SINGLE_BLOCKS_PLUS_ONE_SUBSCRIPTION]
	,P3_HAS_STATUS_BY_SUBSCR_TYPE: [SINGLE_BLOCKS_SUBSCRIPTION, SINGLE_BLOCKS_PLUS_ONE_SUBSCRIPTION, HALF_YEAR_SUBSCRIPTION]
	,P4_HAS_STATUS_BY_SUBSCR_TYPE: [SINGLE_BLOCKS_SUBSCRIPTION, SINGLE_BLOCKS_PLUS_ONE_SUBSCRIPTION]

	,paymentTypes: [
		{ label: 'Contanti', value: 'C' }
		,{ label: 'Carta', value: 'B' }
	]

};

export default DEFS;
