import { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams, Link } from "react-router-dom";
import { toJS } from 'mobx';
import { useSubscriptionStore, useCourseStore, useAuthStore } from '../hooks/use-stores';
import { getAbsoluteURL, getMapSubscriptionObjFn, getIdFromPopulatedObjOrObjectId, getExtendedCourseName, getTableFilterProps } from '../common/common';
import Defs from '../common/defs';
import { observer } from 'mobx-react-lite';
import { Table, Input, InputNumber, Space, Button, Spin, Grid, Select, Form, Typography, Badge, Popconfirm, notification, Modal, DatePicker, Alert } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, LoadingOutlined, PrinterOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import isMobileChecker from 'is-mobile';
import moment from 'moment';
import ModalPromptConfirm from '../common/modal-prompt-confirm';

import ScrollToTopOnMount from '../ScrollToTopOnMount';

const { useBreakpoint } = Grid;
/*
const bulkActionsSelectOptions = [
	{ label: 'Cambia in blocco la data di fine iscrizione', value: 'changeEndDate' }
	,{ label: 'Esporta righe selezionate in formato Excel', value: 'exportXLS' }
];
const paymentPeriodSelectOptions = [
	{ label: 'P1', value: '1' }
	,{ label: 'P2', value: '2' }
	,{ label: 'P3', value: '3' }
	,{ label: 'P4', value: '4' }
];
*/

const Subscriptions = observer( (props) => {
	const isMobile = isMobileChecker();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const subscriptionStore = useSubscriptionStore();
	const authStore = useAuthStore();
	//const courseStore = useCourseStore();
	const [pagination, setPagination] = useState({ pageSize: isMobile ? 50 : 100 });
	const [tableSearchState, setTableSearchState] = useState({});
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	//const [coursesSelectOptions, setCoursesSelectOptions] = useState([]);
	const searchInputRef = useRef();
	const timerUserFilterRef = useRef();
	const [userInputValue, setUserInputValue] = useState();
	//const [bulkActionsModalVisible, setBulkActionsModalVisible] = useState(false);
	//const [bulkActionChangeEndDateForm] = Form.useForm();
	//const [bulkActionsSelectValue, setBulkActionsSelectValue] = useState(null);
	const screens = useBreakpoint();
	const stillSettlingScreens = useMemo( () => Object.keys(screens).length == 0, [screens]);
	//console.log(tableSearchState);

	const tableHasFilters = useMemo( () => {
		return Object.keys(tableSearchState).some( key => {
			return (tableSearchState[key] && tableSearchState[key] instanceof Array && tableSearchState[key].length > 0);
		});
	}, [tableSearchState]);

	const handleSearch = (selectedKeys, confirm, colKeys) => {
		confirm();
	};

	const handleReset = (clearFilters, colKeys, confirm, currKey) => {
		if (currKey === '_userTxt') {
			clearTimeout(timerUserFilterRef.current);
			setUserInputValue(null);
		}
		clearFilters();
		confirm();
		setTableSearchState({
			...tableSearchState
			,[currKey]: []
		});
	};
	const getFilterProps = (colKeys, opts = {}) => {
		opts.currKey = opts.currKey || colKeys[0];
		const customFilterFn = (dataIndex, value, record) => {
			//console.log('eee', dataIndex, value, record);
			if (dataIndex == '_courseTxt' && value.indexOf('ID:') === 0) {
				const courseId = getIdFromPopulatedObjOrObjectId(record.courseId);
				return (courseId == value.replace(/^ID:/, ''));
			}
			else if (dataIndex == '_userTxt' && value.indexOf('ID:') === 0) {
				const userId = getIdFromPopulatedObjOrObjectId(record.userId);
				return (userId == value.replace(/^ID:/, ''));
			}
			return null;
		}
		return getTableFilterProps({ colKeys, searchInputRef, handleSearch, handleReset, setTableSearchState, tableSearchState, customFilterFn, ...opts })
	};

	const resetFilters = () => {
		clearTimeout(timerUserFilterRef.current);
		setUserInputValue(null);
		setTableSearchState({});
	}

	const columns = useMemo( () => ([
		/*{
			title: 'Allievo'
			,key: '_userTxt'
			,dataIndex: '_userTxt'
			,...getFilterProps(screens.lg ? ['_userTxt'] : ['_userTxt', '_courseTxt', '_expTxt'], { currKey: '_userTxt' })
			,sorter: screens.lg ? 
				(a, b, o) => ( String(a._userTxt).localeCompare(b._userTxt) )
				: (a, b, o) => ( String([a._userTxt, a._courseTxt].join(',')).localeCompare([b._userTxt, b._courseTxt].join(',')) )
			,width: 110
			//,fixed: 'left'
			,render: (text, record) => (
				screens.lg ?
					<Link to={getAbsoluteURL(`/subscriptions/${record._id}/edit`)}>{text}</Link>
					: (<Link to={getAbsoluteURL(`/subscriptions/${record._id}/edit`)}>
						{text}<br/>
						Tessera #{record.cardSeqNo}
						<div className="mt-10">
						{record._courseTxt}<br/>
						Scadenza: {record._expTxt}
						</div>
					</Link>
					)
			)
		}
		,*/
		{
			title: 'Corso'
			,key: '_courseTxt'
			,dataIndex: '_courseTxt'
			,...getFilterProps(['_courseTxt'], { currKey: '_courseTxt' })
			,sorter: (a, b, o) => ( String(a._courseTxt).localeCompare(b._courseTxt) )
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 160
			,render: (text, record) => (
				screens.lg ? <span>{text}</span>
				: (<div>
					{text}
					{record._extraInfo?._courseWeekdaysAndTimesTxt &&
					<div>
						Giorno e ora corso: {record._extraInfo._courseWeekdaysAndTimesTxt}
					</div>}
					<div className="mt-10">
						<div>
							Scadenza iscrizione: <Badge dot offset={[3,1]} count={record._expired ? 1 : 0}>
								<Typography.Text type={record._expired ? 'danger' : 'default'}>{record._expTxt}</Typography.Text>
							</Badge>
						</div>
						<small>Tessera #{record.cardSeqNo}</small>
					</div>
				</div>)
			)
		}
		,{
			title: 'Ts #'
			,key: 'cardSeqNo'
			,dataIndex: 'cardSeqNo'
			//,...getFilterProps(['cardSeqNo'])
			,defaultSortOrder: 'ascend'
			,responsive: ['lg', 'xl', 'xxl']
			,sorter: (a, b, o) => ( Number(a.cardSeqNo) - Number(b.cardSeqNo) )
			,width: 35
		}
		,{
			title: 'Scadenza'
			,key: '_expTxt'
			,dataIndex: '_expTxt'
			,...getFilterProps(['_expTxt'], { currKey: '_expTxt' })
			,sorter: (a, b, o) => ( String(a._expTxt).localeCompare(b._expTxt) )
			,responsive: ['lg', 'xl', 'xxl']
			,width: 65
			,render: (text, record) => (
				<span className="text-small">
					<Badge dot offset={[3,1]} count={record._expired ? 1 : 0}>
						<Typography.Text type={record._expired ? 'danger' : 'default'}>{record._expTxt}</Typography.Text>
					</Badge>
				</span>
			)
		}
		,{
			title: 'Tesseramento'
			,key: 'membershipAmount'
			,dataIndex: 'membershipAmount'
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 60
			,render: (text, record) => (
				<>{ text > 0 ? Number(text).toFixed(2) : null }</>
			)
		}
		,{
			title: 'Pagamento 1'
			,key: 'payment1.amount'
			,dataIndex: ['payment1', 'amount']
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 60
			,render: (text, record) => (
				<>{ text > 0 ? 'Sì' : null }</>
			)
		}
		,{
			title: 'Pagamento 2'
			,key: 'payment2.amount'
			,dataIndex: ['payment2', 'amount']
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 60
			,render: (text, record) => (
				record._p2Status ? <div className={`subscription-status-block subscription-status-${record._p2Status}`}>{text > 0 ? 'Sì' : 'No'}</div>
				: <span>{text > 0 ? 'Sì' : null}</span>
			)
		}
		,{
			title: 'Pagamento 3'
			,key: 'payment3.amount'
			,dataIndex: ['payment3', 'amount']
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 60
			,render: (text, record) => (
				record._p3Status ? <div className={`subscription-status-block subscription-status-${record._p3Status}`}>{text > 0 ? 'Sì' : 'No'}</div>
				: <span>{text > 0 ? 'Sì' : null}</span>
			)
		}
		,{
			title: 'Pagamento 4'
			,key: 'payment4.amount'
			,dataIndex: ['payment4', 'amount']
			//,responsive: ['lg', 'xl', 'xxl']
			,width: 60
			,render: (text, record) => (
				record._p4Status ? <div className={`subscription-status-block subscription-status-${record._p4Status}`}>{text > 0 ? 'Sì' : 'No'}</div>
				: <span>{text > 0 ? 'Sì' : null}</span>
			)
		}
		,{
			title: 'Giorni e orari'
			,key: '_extraInfo._courseWeekdaysAndTimesTxt'
			,dataIndex: ['_extraInfo', '_courseWeekdaysAndTimesTxt']
			,responsive: ['lg', 'xl', 'xxl']
			,width: 100
		}
		
		
	]), [tableSearchState, screens]);

	useEffect( () => {
		let c = searchParams.get('c'), u = searchParams.get('u');
		let presetTableSearchState = {};
		if (c) {
			presetTableSearchState._courseTxt = [`ID:${c}`];
		}
		if (u) {
			presetTableSearchState._userTxt = [`ID:${u}`];
			
		}
		if (Object.keys(presetTableSearchState).length > 0) {
			if (u) {
				setUserInputValue(`ID:${u}`);
			}
			setTableSearchState({
				...tableSearchState
				,...presetTableSearchState
			});
		}

		_updateSubscriptions();

		/* we won't need courses in this page
		courseStore.load({
			fetchAll: true
			,dontSet: true
			,query: {
				//supporting $null requires an hook
				'$sort': { dateEnd: -1, dateStart: -1, name: 1 }
				,...(location.hash != '#showarchived' ? { archived: { '$in': ['$null', false] } } : null)
				,'$limit': 500
			}

		}).then( items => {
			let mapped = items.map( i => ({
				value: i._id
				,label: getExtendedCourseName(i)
			}) );
			setCoursesSelectOptions(mapped);
		});
		*/
	}, []);

	const _updateSubscriptions = () => {
		subscriptionStore.load({
			fetchAll: true
			,dontSet: true
			,query: {
				'userId': authStore.id
				,'$sort': { cardSeqNo: 1, createdAt: 1 }
				,...(location.hash != '#showarchived' ? { archived: { '$in': ['$null', false] } } : null)
				,'$populate': ['courseId', 'userId']
			}
		}).then( items => {
			const mapSubscriptionObj = getMapSubscriptionObjFn( moment(), { extraCourseInfo: true } );
			let mapped = items.map( mapSubscriptionObj );
			subscriptionStore.setItems(mapped);
		}).catch( err => {
			console.error(err);
			Modal.warning({
				title: 'Attenzione'
				,content: <div>
					<p>Non riesco ad ottenere la lista delle tue iscrizioni in questo momento a causa di un errore: {err.message}.</p>
					<p>Verranno mostrate quelle scaricate in precedenza ed eventualmente presenti in memoria</p>
				</div>
			});
		});
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		if (filters._userTxt) {
			setUserInputValue(filters._userTxt);
		}
		setTableSearchState(filters);
	};


	const onSelectChange = _selectedRowKeys => {
		setSelectedRowKeys(_selectedRowKeys);
		console.log(_selectedRowKeys);
	};

	//always disabled, useless atm
	const rowSelection = screens.lg && false ? {
		selectedRowKeys,
		onChange: onSelectChange
	} : null;

	const courseSelectChanged = (val) => {
		console.log('select change', val);
		setTableSearchState({
			...tableSearchState
			,_courseTxt: [`ID:${val}`]
		});
	}
	const userFilterChanged = (e) => {
		clearTimeout(timerUserFilterRef.current);
		const val = e.target.value;
		setUserInputValue(val);
		timerUserFilterRef.current = setTimeout( () => {
			setTableSearchState({
				...tableSearchState
				,_userTxt: val ? [val] : []
			});
		}, 400);
	}

	const deleteItem = record => {
		subscriptionStore.remove(record._id).then( () => {
			notification.success({
				message: 'Ok!'
				,description: `L'iscrizione è stata eliminata con successo`
				,duration: 5
			});
		}).catch( err => {
			notification.error({
				message: 'Errore'
				,description: `Non è stato possibile eliminare l'iscrizione: ${err.message || JSON.stringify(err)}`
				,duration: 6
			});
		});
	}



	const calcTableSummary = (pageData) => {
		/*if (!screens.lg) {
			return null;
		}*/
		let totMembershipAmount = 0, totP1Amount = 0, totP2Amount = 0, totP3Amount = 0, totP4Amount = 0, maxCardSeqNo = 0;
		pageData.forEach( row => {
			totMembershipAmount += row.membershipAmount > 0 ? row.membershipAmount : 0;
			totP1Amount += row.payment1 instanceof Object && row.payment1.amount > 0 ? row.payment1.amount : 0;
			totP2Amount += row.payment2 instanceof Object && row.payment2.amount > 0 ? row.payment2.amount : 0;
			totP3Amount += row.payment3 instanceof Object && row.payment3.amount > 0 ? row.payment3.amount : 0;
			totP4Amount += row.payment4 instanceof Object && row.payment4.amount > 0 ? row.payment4.amount : 0;
			if (row.cardSeqNo > 0) {
				maxCardSeqNo = Math.max(maxCardSeqNo, row.cardSeqNo);
			}
		});
		let colNdx = 0;
		let diff1 = screens.lg ? 1 : 0;
		return <Table.Summary fixed>
			<Table.Summary.Row>
				<Table.Summary.Cell index={colNdx++} colSpan={1}>Totali</Table.Summary.Cell>
				{screens.lg ? <Table.Summary.Cell index={colNdx++} colSpan={2}> </Table.Summary.Cell> : null }
				<Table.Summary.Cell index={diff1+colNdx++} colSpan={1}>{totMembershipAmount.toFixed(2)}</Table.Summary.Cell>
				<Table.Summary.Cell index={diff1+colNdx++} colSpan={1}>{totP1Amount.toFixed(2)}</Table.Summary.Cell>
				<Table.Summary.Cell index={diff1+colNdx++} colSpan={1}>{totP2Amount.toFixed(2)}</Table.Summary.Cell>
				<Table.Summary.Cell index={diff1+colNdx++} colSpan={1}>{totP3Amount.toFixed(2)}</Table.Summary.Cell>
				<Table.Summary.Cell index={diff1+colNdx++} colSpan={1}>{totP4Amount.toFixed(2)}</Table.Summary.Cell>
				{screens.lg ? <Table.Summary.Cell index={diff1+colNdx++} colSpan={1}></Table.Summary.Cell> : null }
			</Table.Summary.Row>
		</Table.Summary>
	};

/*
	const exportXLS = ({ modal }) => {
		let rows = subscriptionStore.items.filter( r => (selectedRowKeys.indexOf(r._id) !== -1) );
		let outputRows = rows.map( r => ({
			'Allievo': r._userTxt, 'Tessera #': r.cardSeqNo, 'Corso': r._courseTxt
			,'Scadenza': `${r._expTxt} ${r._expired ? '*' : ''}`, 'Tipo': r.type
			,'Tesseramento': r.membershipAmount > 0 ? r.membershipAmount.toFixed(2) : ''
			,'Pagamento 1': r.payment1?.amount > 0 ? r.payment1.amount.toFixed(2) : ''
			,'P1 Tipo': r.payment1?.type || ''
			,'Pagamento 2': r.payment2?.amount > 0 ? r.payment2.amount.toFixed(2) : ''
			,'P2 Tipo': r.payment2?.type || ''
			,'Pagamento 3': r.payment3?.amount > 0 ? r.payment3.amount.toFixed(2) : ''
			,'P3 Tipo': r.payment3?.type || ''
			,'Pagamento 4': r.payment4?.amount > 0 ? r.payment4.amount.toFixed(2) : ''
			,'P4 Tipo': r.payment4?.type || ''
		}) );
		const worksheet = XLSX.utils.json_to_sheet(outputRows);
		worksheet["!cols"] = [ { wch: 50 }, { wch: 9 }, { wch: 50 }, { wch: 12 }, { wch: 6 }, { wch: 12 }
			,{ wch: 12 }, { wch: 6 }, { wch: 12 }, { wch: 6 }, { wch: 12 }, { wch: 6 }, { wch: 12 }, { wch: 6 }
		];
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Iscrizioni");
		XLSX.writeFile(workbook, `ZIC_Iscrizioni_${moment().format('YYYYMMDDHHmmssS')}.xlsx`);
		if (modal) {
			setTimeout( () => modal.destroy(), 300);
		}
	}
*/

	return (<Spin spinning={subscriptionStore._isLoading || stillSettlingScreens} indicator={<LoadingOutlined style={{ fontSize: 46 }} spin />}>
		<ScrollToTopOnMount />
		<p><small><em>La tabella è piuttosto larga, puoi scorrerla col dito o col mouse. Ricorda che puoi applicare dei filtri premendo sulla lente di ingrandimento nell'intestazione delle colonne</em></small></p>
		{tableHasFilters && <Alert message={<small>Sono presenti filtri nella tabella</small>} type="warning" />}
		<Table  size="small"
			dataSource={subscriptionStore.items.slice()}
			columns={columns}
			rowKey={'_id'}
			/*expandable={{
				expandedRowRender: expandedRowRenderer,
				rowExpandable: record => true,
			}}*/
			pagination={pagination}
			onChange={handleTableChange}
			rowSelection={rowSelection}
			scroll={{ x: 1150, }}
			summary={calcTableSummary}
		/>
	</Spin>);
} );


export default Subscriptions;

